.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-bold {
  font-weight: bold;
}


.f-primary {
  color: white;
}

.f-secondary {
  color: #ffffff;
}

.spacing-6 {
  margin-bottom: 6px;
}

.spacing-8 {
  margin-bottom: 8px;
}

.spacing-15 {
  margin-bottom: 15px;
}

.spacing-20 {
  margin-bottom: 20px;
}

.spacing-30 {
  margin-bottom: 30px;
}

.spacing-45 {
  margin-bottom: 45px;
}


.btn-primary {
  background-color: #ED622E!important;
  border-color: #ED622E!important;
  color: white!important;
  min-width: 130px!important;
}

.btn-secondary {
  background-color: #707070!important;
  border-color: #707070!important;
  color: white!important;
}

.form-check-input:checked {
  background-color: #5DA9D7!important; /* Active color when checked */
  border-color: #5DA9D7!important; /* Active border color when checked */
}

.form-check-input:focus {
  box-shadow: 0 0 3px rgba(93, 169, 215, 0.5);
}
