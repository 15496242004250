/* General styles */
@import "assets/styles/styles.css";
*{
  font-family: 'montserrat', sans-serif;
}

.home-main {
  padding: 20px;
}

.topbar-logo {
  width: 100%;
  max-width: 100px; /* Adjust as necessary */
}

.logo-text-master, .logo-text-employee {
  font-size: 2rem; /* Adjust as necessary */
}

.content-main {
  margin-top: 0px;
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .logo-text-master, .logo-text-employee {
    font-size: 1.5rem;
  }
  .topbar-logo {
    max-width: 80px;
  }

  .content-main {
    margin-top: 50px;
  }
}

@media (max-width: 992px) {
  .logo-text-master, .logo-text-employee {
    font-size: 1.25rem;
  }
  .topbar-logo {
    max-width: 70px;
  }
}

@media (max-width: 768px) {
  .row.d-flex.align-items-center.justify-content-between {
    flex-direction: column;
    align-items: center;
  }

  .col-lg-5, .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-main {
    padding: 10px;
  }

  .content-main {
    margin-top: 100px;
  }
}

@media (max-width: 576px) {
  .logo-text-master, .logo-text-employee {
    font-size: 1rem;
  }
  .topbar-logo {
    max-width: 50px;
  }

  .content-main {
    margin-top: 50px;
  }
}/* You can add global styles to this file, and also import other style files */

.toast-container .ngx-toastr {
  box-shadow: 0 0 12px #3b3b3b !important;
}


.label-class {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


/* Global table styles */
table.table-hover {
  thead th {
    background-color: #2D2D2D;
    color: #d5d5d5;

  }

  /* Rounded corners for the first and last th elements */
  thead th:first-child {
    border-top-left-radius: 8px!important;
  }

  thead th:last-child {
    border-top-right-radius: 8px!important;
  }

  /* Default table body cell styles */
  tbody td {
    background-color: #202020;
    color: #d5d5d5;
    border: none;
  }
}

/* Cursor pointer for clickable elements */
.cursor-pointer {
  cursor: pointer;
}

/* Override specific icons to have pointer and color by default */
tbody td i.fa-regular.fa-trash-can i.fa-regular.fa-pen{
  cursor: pointer;
  color: #909699; /* Bootstrap's default text-danger color */
}

/* Additional styles */
.table-row-hover:hover {
  background-color: #262C36 !important;
  cursor: pointer;
}



.btn {
  min-width: 80px;
}



f-12 {
  font-size: 12px;
}

f-14 {
  font-size: 14px;
}

f-16 {
  font-size: 16px;
}

f-18 {
  font-size: 18px;
}

f-20 {
  font-size: 20px;
}

spacing-45 {
  margin-bottom: 45px;
}

spacing-8 {
  margin-bottom: 8px;
}

spacing-20 {
  margin-bottom: 20px;
}

spacing-30 {
  margin-bottom: 30px;
}


.rounded-table {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #909699;
  background-color: #343a40;
  color: #f8f9fa; /* Light text */
}
.rounded-table th, .rounded-table td {
  border-bottom: 1px solid #495055;
  height: 42px;
  /*border: none;*/
}
.rounded-table thead {
  background-color: #495057;
  color: #f8f9fa;
}
.rounded-table tbody tr {
  border-bottom: 1px solid #495055;
  height: 72px;

  /*display: flex;*/
  /*align-items: center;*/
}
.rounded-table tbody tr:last-child {
  border-bottom: none;
}

.table > tbody > tr > td {
  vertical-align: middle;
}


.form-control::-moz-placeholder{
  color:#999;
  opacity:1
}

.form-control:-ms-input-placeholder{
  color:#999
}

.form-control::-webkit-input-placeholder{
  color:#999
}




